//sidebarNav options
export const API_KEYS = "API";
export const PROJECT = "Settings";
export const MEMBERS = "Members";
export const PROFILE = "Profile";
export const USERS = "Users";
export const SUBSCRIPTIONS = "Subscription";
export const USAGE = "Usage";

export const REMOVE_ACCOUNT = "remove_account";
export const DELETE_PROJECT = "delete_project";
export const REMOVE_KEY = "remove_key";
export const REMOVE_MEMBER = "remove_member";
export const CUSTOM = "Custom";
export const DELETE_PROEJCT_USERS = "delete_project_users";
export const UNSUBSCRIBE = "unsubscribe";
