import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const UserConfirmedPage = () => {
  return (
    <Container>
      <Message>
        <h1>Your account is now activated.</h1>
        <p>You can start using Emotii with your account.</p>
      </Message>

      <Section>
        <SectionTitle>Download the Products</SectionTitle>
        <p>
          Depending on your subscription type (ask your administrator), you
          might need to download a client app.
        </p>
      </Section>

      <Section>
        <SectionTitle>IM Bots</SectionTitle>
        <ButtonWrapper>
          <a href="https://appsource.microsoft.com/en-us/product/office/WA200007550?tab=Overview">
            MS Teams
          </a>
          <a href="https://emotii-slack-bot-cdf4d6dbfa34.herokuapp.com/slack/install">
            Slack
          </a>
        </ButtonWrapper>
      </Section>

      <Section>
        <SectionTitle>Mobile Apps</SectionTitle>
        <ButtonWrapper>
          <a href="https://play.google.com/store/apps/details?id=com.emotii.emotii&pli=1">
            Android
          </a>
          <a href="https://apps.apple.com/us/app/emotii-realtime-translator/id6476883749">
            iOS
          </a>
        </ButtonWrapper>
      </Section>

      <Section>
        <SectionTitle>Web App</SectionTitle>
        <p>
          <a href="https://app.emotii.ai">Web app</a>
        </p>
      </Section>

      <Section>
        <SectionTitle>Desktop Apps</SectionTitle>
        <ButtonWrapper>
          <a href="https://drive.usercontent.google.com/download?id=1wNck5FA6npdoy6NA5Nuv8PtuoGflfRAH&export=download&authuser=0&confirm=t&uuid=6ac91f86-5091-4e50-a22e-822194778c44&at=AENtkXakOuYwsEn5HxDBu2QFWeHv%3A1732718969800">
            Windows
          </a>
          <a href="https://drive.usercontent.google.com/download?id=1gvTGJu-8U6lovFd1BhZUtCg69byl2yIx&export=download&authuser=2&confirm=t&uuid=94fe7659-4383-46e6-8bc3-ec6f8100c001&at=AENtkXaEbIkMS5GLCheTpIrF1HwJ%3A1732718845509">
            Mac (Sillicon)
          </a>
          <a href="https://drive.usercontent.google.com/download?id=1ZFABwXeY83uoSAZRutAm7X45N1ZtLMoR&export=download&authuser=0">
            Mac (Intel)
          </a>
        </ButtonWrapper>
      </Section>
    </Container>
  );
};

export default UserConfirmedPage;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px;
  background-color: ${lightThemeColors.background};
`;

const Message = styled.div`
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  p {
    color: ${lightThemeColors.darkColor50};
    font-size: 16px;
    font-weight: 400;
  }
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    color: ${lightThemeColors.darkColor50};
    font-size: 16px;
    font-weight: 400;
  }
`;

const SectionTitle = styled.h2`
  color: ${lightThemeColors.darkColor};
  font-size: 20px;
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const Button = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: ${lightThemeColors.primaryColor};
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${lightThemeColors.primaryColorHover};
  }
`;
