import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/CloseModalIcon.svg";
import { motion } from "framer-motion";
import PrimaryButton from "../Generic/PrimaryButton";

const IndividualSubscriptionTypeModal = ({
  setIsModalOpen,
  purchaseSubscription,
}) => {
  const [inputValue, setInputValue] = useState(""); // State to hold the selected platform
  const ref = useRef();

  const handleSubscribe = () => {
    if (inputValue !== null) {
      const planName = "individual-" + inputValue;
      setIsModalOpen(false);
      purchaseSubscription(planName);
    }
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsModalOpen]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <FullscreenContainer>
      <InviteModal
        ref={ref}
        as={motion.div}
        initial={{ translateX: "65vw" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "65vw" }}
        transition={{ duration: 0.4 }}
      >
        <Line>
          <HeadWrapper>
            <h1>Select platform</h1>
          </HeadWrapper>
          <CloneBtn
            onClick={() => setIsModalOpen(false)}
            style={{ marginLeft: "auto" }}
          >
            <CloseModalIcon />
          </CloneBtn>
        </Line>

        {/* Custom Select styled like CustomInput */}
        <Line>
          <CustomSelectWrapper>


            <CustomSelect
              value={inputValue}
              onChange={(e) => setInputValue(e.currentTarget.value)}
            >
              <option value="">Pick one</option>
              <option value="mobile">Mobile</option>
              <option value="web">Web</option>
              <option value="desktop">Desktop</option>
              <option value="teams">MS Teams</option>
              <option value="slack">Slack</option>
            </CustomSelect>
          </CustomSelectWrapper>
        </Line>

        <Line>
          <PrimaryButton
            disabled={inputValue.length < 1}
            styled={{ padding: "12px", width: "100%" }}
            text={"Subscribe"}
            onClick={handleSubscribe}
          />
        </Line>
      </InviteModal>
    </FullscreenContainer>
  );
};

export default IndividualSubscriptionTypeModal;

const FullscreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const InviteModal = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 400px;
  max-height: 600px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  gap: 20px;
  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CustomSelect = styled.select`
  border: none;
  outline: none;
  width: 100%;
  color: ${lightThemeColors.darkColor};
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  background: ${lightThemeColors.background};
  // border: 1px solid ${lightThemeColors.sidebar.menuItemBorder};
  cursor:pointer;
  &::placeholder {
    color: rgba(214, 216, 219, 1);
  }
`;


const CustomSelectWrapper = styled.div`
width:100%;
display:flex;
padding:10px;
 border-radius: 5px;
 border: 1px solid ${lightThemeColors.sidebar.menuItemBorder};
`