import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import SubscriptionPlanCard from "../../Components/SubscriptionsComponents/SubscriptionPlanCard";

const SubscriptionCards = ({ purchaseSubscription, contactSales }) => {
  return (
    <Content>
      <SubscriptionsContainer>
        <SubscriptionPlanCard
          plan={"teams"}
          handleUpgradeSubscription={() => {
            purchaseSubscription("Emotii - Teams");
          }}
        />
        <SubscriptionPlanCard
          plan={"slack"}
          handleUpgradeSubscription={() => {
            purchaseSubscription("Emotii - Slack");
          }}
        />
        <SubscriptionPlanCard
          plan={"teams+slack"}
          handleUpgradeSubscription={() => {
            purchaseSubscription("Emotii - Teams + Slack");
          }}
        />
        <SubscriptionPlanCard
          plan={"enterprise"}
          handleContactSales={() => {
            contactSales();
          }}
        />
      </SubscriptionsContainer>
      <SubscriptionsContainer>
        <SubscriptionPlanCard
          plan={"team+apps"}
          handleUpgradeSubscription={() => {
            purchaseSubscription("Emotii - Teams + Apps");
          }}
        />
        <SubscriptionPlanCard
          plan={"slack+apps"}
          handleUpgradeSubscription={() => {
            purchaseSubscription("Emotii - Slack + Apps");
          }}
        />
        <SubscriptionPlanCard
          plan={"teams+slack+apps"}
          handleUpgradeSubscription={() => {
            purchaseSubscription("Emotii - Teams + Slack + Apps");
          }}
        />
      </SubscriptionsContainer>
      <Spacer></Spacer>
    </Content>
  );
};

export default SubscriptionCards;

const Spacer = styled.div`
  height: 20px;
`;
const SubtitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start; /* Align the container's content to the left */

  color: ${lightThemeColors.darkColor};
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left; /* Align text to the left */
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  overflow: auto;
`;
const SubscriptionsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
`;
