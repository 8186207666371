import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import PrimaryButton from "../../Components/Generic/PrimaryButton";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIcon.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copyIcon.svg";
import { ReactComponent as DotMenuIcon } from "../../assets/icons/dotMenuIcon.svg";
import SecondaryButton from "../../Components/Generic/SecondaryButton";
import { AnimatePresence } from "framer-motion";
import AddApiKeyModal from "../../Components/ApiKeyPage/AddApiKeyModal";
import { useKeysContext } from "../../context/useKeysContext";
import { copyToClipboard } from "../../helper/copy";
import { Popover } from "react-tiny-popover";
import PopoverMenu from "../../Components/Generic/PopoverMenu";
import { useProjectContext } from "../../context/useProjectContext";
import { useOutletContext } from "react-router-dom";
import { showGenericError } from "../../helper/Notifications";
import DocumentationPage from "../DocumentationPage/DocumentationPage";
import DocumentationPageModal from "../../Components/ApiKeyPage/DocumentationPageModal";

const ApiKeysPage = () => {
  const [showAddKeyModal, setShowAddKeyModal] = useState(false);
  const [showDocumentationModal, setShowDocumentationModal] = useState(false);
  const { keys, deleteKey, getKeys } = useKeysContext();
  const { selectedProject } = useProjectContext();
  const { setLoading } = useOutletContext();

  const mapKey = (key) => {
    if (key.length > 20) {
      return key.slice(0, 6) + "***************" + key.slice(-6);
    } else return key;
  };

  const handleGetKeys = () => {
    getKeys(
      selectedProject.id,
      (response) => {
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleDeleteKey = (keyId) => {
    setLoading(true);
    deleteKey(
      selectedProject.id,
      keyId,
      (response) => {
        handleGetKeys();
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleMenuAction = (item) => {
    handleDeleteKey(item.id);
  };

  return (
    <Container>
      <AnimatePresence>
        {showAddKeyModal && (
          <AddApiKeyModal setIsModalOpen={setShowAddKeyModal} />
        )}

        {showDocumentationModal && (
          <DocumentationPageModal
            setShowDocumentationModal={setShowDocumentationModal}
          />
        )}
      </AnimatePresence>
      <PageContent>
        <Header>
          <HeaderTitle>API</HeaderTitle>
          <HeaderButtonsContainer>
            <SecondaryButton
              styled={{ marginLeft: "auto" }}
              text={"See documentation"}
              onClick={() => setShowDocumentationModal(true)}
            />
            <PrimaryButton
              styled={{ marginLeft: "auto" }}
              icon={<PlusIcon />}
              text={"Add new API Key"}
              onClick={() => setShowAddKeyModal(true)}
            />
          </HeaderButtonsContainer>
        </Header>
        <ListWrapper>
          <ListHeaderTitle>Generated keys</ListHeaderTitle>
          <ListHeader>
            <HeaderColumn>
              <p>Name</p>
            </HeaderColumn>
            <HeaderColumn style={{ minWidth: "300px" }}>
              <p>Key</p>
            </HeaderColumn>
            <HeaderColumn>
              <p></p>
            </HeaderColumn>
          </ListHeader>
          {/* Check if keys array is empty or undefined */}
          {keys && keys.length > 0 ? (
            keys.map((key, index) => (
              <ListLine key={key.id}>
                <ListColumn>
                  <h2>{key.name}</h2>
                </ListColumn>
                <ListColumn style={{ minWidth: "300px" }}>
                  <h3>{mapKey(key.key)}</h3>
                  <SecondaryButton
                    onClick={() => copyToClipboard(key.key)}
                    icon={
                      <div style={{ height: "12px", display: "flex" }}>
                        <CopyIcon />
                      </div>
                    }
                    text={"Copy"}
                    style={{ border: "none", gap: "5px" }}
                  />
                </ListColumn>
                <ListColumn style={{ minWidth: "50px" }}>
                  <PopoverMenu handleAction={handleMenuAction} item={key} />
                </ListColumn>
              </ListLine>
            ))
          ) : (
            <NoKeysText>
              <p>
                You haven't generated any keys, add one by pressing the add new
                button.
              </p>
            </NoKeysText> // Display this message if there are no keys
          )}
        </ListWrapper>
      </PageContent>
    </Container>
  );
};

export default ApiKeysPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
  padding-bottom: 30px;
  padding: 40px 30px;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderButtonsContainer = styled.div`
  margin-left: auto;
  display: flex;
  gap: 20px;
  align-items: center;
`;

const HeaderTitle = styled.h1`
color:${lightThemeColors.darkColor};
font-size: 30px;
font-weight: 600;
line-height: 36px
text-align: left;

`;

const NoKeysText = styled.div`
  color: ${lightThemeColors.darkColor50};
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  width: 100%;

  /* Centering text using flexbox */
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  height: 100px; /* Adjust the height as needed */
`;

const ListHeaderTitle = styled.h1`
color:${lightThemeColors.darkColor};
margin-bottom: 20px;
font-size: 24px;
font-weight:600;
line-height: 36px
text-align: left;

`;
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ListHeader = styled(Line)`
  background: ${lightThemeColors.menuBackground};
  padding: 13px 20px;
  gap: 20px;
  width: 100%;
  min-width: 600px;
`;

const HeaderColumn = styled.div`
  display: flex;
  width: 100%;
  min-width: 150px;
  align-items: center;
  p {
    color: ${lightThemeColors.darkColor50};
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
  }
`;

const ListLine = styled(Line)`
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
  padding: 13px 20px;
  gap: 20px;
  width: 100%;
  min-width: 450px;
`;
const ListColumn = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 150px;
  position: relative;
  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    position: relative;
  }
  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${lightThemeColors.darkColor};
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
`;
