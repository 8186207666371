import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import DeleteConfirmationChild from "../../Components/Generic/DeleteConfirmationWithChild";
import SecondaryButton from "../../Components/Generic/SecondaryButton";
import { useState } from "react";
import { UNSUBSCRIBE } from "../../constants/OptionsConstants";
import PrimaryButton from "../../Components/Generic/PrimaryButton";

const SubscriptionDetails = ({
  subscription,
  cancelSubscription,
  viewInvoices,
}) => {
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  var amountDueDollars = "";
  var formattedDate = "";
  var planName = "";

  if (subscription) {
    if (subscription.plan_name) {
      planName =
        subscription.plan_name.charAt(0).toUpperCase() +
        subscription.plan_name.slice(1).toLowerCase();
    } else {
      planName = "Unknown Plan"; // Default value when plan_name is null or undefined
    }

    console.log(subscription);

    amountDueDollars = (subscription.amount_due / 100).toFixed(2);

    const date = new Date(subscription.next_payment_date);
    formattedDate = `${String(date.getDate()).padStart(2, "0")}/${String(
      date.getMonth() + 1
    ).padStart(2, "0")}/${date.getFullYear()}`;
  }

  return (
    <Content>
      <DetailsRow>
        <Detail>
          <Label>Plan</Label>
          <Value>{planName}</Value>{" "}
        </Detail>
        <Detail>
          <Label>Amount due</Label>
          <Value>${amountDueDollars}</Value>{" "}
        </Detail>
        <Detail>
          <Label>Next payment date</Label>
          <Value>{formattedDate}</Value>{" "}
        </Detail>
      </DetailsRow>
      <ButtonsRow>
        <DeleteConfirmationChild
          open={deleteConfirmOpen}
          setOpen={setDeleteConfirmOpen}
          action={cancelSubscription}
          variant={UNSUBSCRIBE}
          children={
            <SecondaryButton
              variant={lightThemeColors.authentication.inputErrorColor}
              text={"Cancel subscription"}
              onClick={() => setDeleteConfirmOpen(true)}
            />
          }
        />

        <PrimaryButton
          styled={{ padding: "12px" }}
          text={"View invoices"}
          onClick={() => viewInvoices()}
        />
      </ButtonsRow>
    </Content>
  );
};

export default SubscriptionDetails;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  width: 100%;
  background-color: ${lightThemeColors.background};
  border-radius: 8px;
`;

const DetailsRow = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 30px;
  padding: 10px 0;
  padding-bottom: 30px;
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
`;

const Detail = styled.div`
  text-align: left;
  width: 100%;
`;

const Label = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${lightThemeColors.darkColor};
  margin: 0;
`;

const Value = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${lightThemeColors.primaryColor};
  margin: 10px 0 0;
`;

const ButtonsRow = styled.div`
  display: flex;
  gap: 20px;
  justify-content: start;
  width: 100%;
  margin-top: 20px;
`;

const ActionButton = styled.button`
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 600;
  color: white;
  background-color: ${lightThemeColors.primaryColor};
  border: none;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: ${lightThemeColors.primaryColorHover};
  }
`;
