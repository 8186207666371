import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import SecondaryButton from "../../Components/Generic/SecondaryButton";
import { useProjectContext } from "../../context/useProjectContext";
import PrimaryButton from "../../Components/Generic/PrimaryButton";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIcon.svg";
import { ReactComponent as Arrow } from "../../assets/icons/downIcon.svg";
import InviteMultipleUsersModal from "../../Components/UsersPage/InviteMultipleUsersModal";
import { showGenericError } from "../../helper/Notifications";
import { useOutletContext } from "react-router-dom";
import DeleteConfirmationChild from "../../Components/Generic/DeleteConfirmationWithChild";
import { DELETE_PROEJCT_USERS } from "../../constants/OptionsConstants";
import moment from "moment/moment";
import CustomInput from "../../Components/Generic/CustomInput";

const UsersPage = () => {
  const {
    getProjectUsers,
    selectedProject,
    disableProjectUsers,
    projectUsers,
  } = useProjectContext();

  const { setLoading } = useOutletContext();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showAddUsersModal, setShowAddUsersModal] = useState(false);
  const [page, setPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleGetProjectUsers = (projectId, term, page, itemsPerPage) => {
    setLoading(true);
    getProjectUsers(
      projectId,
      term,
      page,
      itemsPerPage,
      (response) => {
        setLoading(false);
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleDeleteUsersFromProject = (users) => {
    setLoading(true);
    disableProjectUsers(
      selectedProject.id,
      users,
      (response) => {
        setSelectedUsers([]);
        handleGetProjectUsers(
          selectedProject.id,
          searchTerm,
          page,
          itemsPerPage
        );
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleCheckChange = (e, user) => {
    if (e.currentTarget.checked) {
      setSelectedUsers((prev) => [...prev, user]);
    } else {
      setSelectedUsers((prev) =>
        prev.filter((item) => item.email !== user.email)
      );
    }
  };

  const handleDeleteSelectedUsers = (item) => {
    const deleteUsersList = item.map((user) => user.email);
    handleDeleteUsersFromProject(deleteUsersList);
  };

  const isCurrentItemSelected = (item) => {
    let found = selectedUsers.find((user) => user.email === item.email);
    if (found) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!selectedProject) {
      return;
    }

    handleGetProjectUsers(selectedProject.id, searchTerm, page, itemsPerPage);
  }, [selectedProject, page, itemsPerPage, searchTerm]);

  console.log(projectUsers);
  return (
    <Container>
      {showAddUsersModal && (
        <InviteMultipleUsersModal
          setIsModalOpen={setShowAddUsersModal}
          handleGetProjectUsers={() =>
            handleGetProjectUsers(
              selectedProject.id,
              searchTerm,
              page,
              itemsPerPage
            )
          }
        />
      )}
      <PageContent>
        <Header>
          <HeaderTitle>Users</HeaderTitle>
          {selectedUsers.length > 0 && (
            <DeleteConfirmationChild
              item={selectedUsers}
              open={deleteConfirmationOpen}
              setOpen={setDeleteConfirmationOpen}
              action={handleDeleteSelectedUsers}
              variant={DELETE_PROEJCT_USERS}
              children={
                <SecondaryButton
                  variant={lightThemeColors.authentication.inputErrorColor}
                  style={{ marginLeft: "auto" }}
                  text={"Remove users"}
                  onClick={() => setDeleteConfirmationOpen(true)}
                />
              }
            />
          )}
          <PrimaryButton
            styled={selectedUsers.length > 0 ? {} : { marginLeft: "auto" }}
            icon={<PlusIcon />}
            text={"Add users"}
            onClick={() => setShowAddUsersModal(true)}
          />
        </Header>
        <Line>
          <CustomInput
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.currentTarget.value)}
            placeholder={"Search"}
            wrapperStyle={{ maxWidth: "250px" }}
          />
        </Line>
        <ListWrapper>
          <ListHeader>
            <HeaderColumn
              style={{ minWidth: "30px", width: "30px" }}
            ></HeaderColumn>
            <HeaderColumn>
              <p>Email</p>
            </HeaderColumn>
            <HeaderColumn>
              <p>Date</p>
            </HeaderColumn>
            <HeaderColumn style={{ width: "30px" }}>
              <p>Email confirmed</p>
            </HeaderColumn>
          </ListHeader>

          {projectUsers && projectUsers.length > 0 ? (
            projectUsers.map((user, index) => (
              <ListLine key={user.id} selected={isCurrentItemSelected(user)}>
                <ListColumn style={{ minWidth: "30px", width: "30px" }}>
                  <input
                    type="checkbox"
                    value={isCurrentItemSelected(user)}
                    onChange={(e) => handleCheckChange(e, user)}
                  />
                </ListColumn>
                <ListColumn>
                  <h3>{user.email}</h3>
                </ListColumn>
                <ListColumn>
                  <p>{moment(user.created_at).format("DD/MM/YYYY")}</p>
                </ListColumn>
                <ListColumn style={{ width: "30px" }}>
                  <p>{user.confirmed ? "Confirmed" : "Not confirmed"}</p>
                </ListColumn>
              </ListLine>
            ))
          ) : (
            <NoKeysText>
              <p>
                Invite users who can access the services included in your
                subscription. Please use the email addresses they use to log in
                to emotii.
              </p>
            </NoKeysText>
          )}
        </ListWrapper>

        <Line
          style={{ marginTop: "auto", flexDirection: "column", gap: "0px" }}
        >
          <Line style={{ gap: "10px", justifyContent: "center" }}>
            <SecondaryButton
              style={{ padding: "5px", minWidth: "50px" }}
              icon={<Arrow style={{ rotate: "180deg", height: "12px" }} />}
              disabled={page === 1}
              onClick={() => setPage((prev) => prev - 1)}
            />
            <SecondaryButton
              style={{ padding: "5px", minWidth: "50px" }}
              icon={<Arrow style={{ height: "12px" }} />}
              disabled={projectUsers.length < itemsPerPage}
              onClick={() => setPage((prev) => prev + 1)}
            />
          </Line>
          <Line>
            <CustomSelectWrapper style={{ marginLeft: "auto" }}>
              <CustomSelect
                value={itemsPerPage}
                onChange={(e) =>
                  setItemsPerPage(parseInt(e.currentTarget.value))
                }
              >
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={1000}>1000</option>
              </CustomSelect>
            </CustomSelectWrapper>
          </Line>
        </Line>
      </PageContent>
    </Container>
  );
};

export default UsersPage;

const NoKeysText = styled.div`
  color: ${lightThemeColors.darkColor50};
  font-size: 16px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  width: 100%;

  /* Centering text using flexbox */
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */
  height: 100px; /* Adjust the height as needed */
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
  padding-bottom: 30px;
  padding: 40px 30px;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const HeaderTitle = styled.h1`
color:${lightThemeColors.darkColor};
font-size: 30px;
font-weight: 600;
line-height: 36px
text-align: left;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ListHeader = styled(Line)`
  background: ${lightThemeColors.menuBackground};
  padding: 13px 20px;
  gap: 20px;
  width: 100%;
  min-width: 450px;
`;

const HeaderColumn = styled.div`
  display: flex;
  width: 100%;
  min-width: 150px;
  align-items: center;
  p {
    color: ${lightThemeColors.darkColor50};
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
  }
`;

const ListLine = styled(Line)`
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
  padding: 13px 20px;
  gap: 20px;
  width: 100%;
  min-width: 450px;
  background: ${(props) =>
    props.selected ? "rgba(0,0,0,0.2)" : "transparent"};
`;
const ListColumn = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 150px;
  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }
  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${lightThemeColors.darkColor};
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
`;

const CustomSelect = styled.select`
  // padding: 5px;
  // border-radius: 5px;
  // border: 1px solid ${lightThemeColors.darkColor50};
  border: none;
  cursor: pointer;
`;

const CustomSelectWrapper = styled.div`
  display: flex;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid ${lightThemeColors.darkColor50};
`;
