import toast from "react-hot-toast";

export const showGenericError = () => {
  toast.error("Something went wrong, please try again");
};

export const showSuccessNotification = (message) => {
  toast.success(message, { duration: 10000 });
};

export const showErrorNotification = (message) => {
  toast.error(message);
};

export const showErrorNotificationWithLink = (message, onSuccess) => {
  toast.error((t) => {
    return (
      <div
        onClick={() => {
          onSuccess();
          toast.dismiss(t.id);
        }}
      >
        {message}
      </div>
    );
  });
};
