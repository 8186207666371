import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/CloseModalIcon.svg";
import { motion } from "framer-motion";
import { useOutletContext } from "react-router-dom";
import PrimaryButton from "../Generic/PrimaryButton";
import CustomInput from "../Generic/CustomInput";
import { useKeysContext } from "../../context/useKeysContext";
import { useProjectContext } from "../../context/useProjectContext";
import {
  showGenericError,
  showSuccessNotification,
} from "../../helper/Notifications";
import DocumentationPage from "../../Pages/DocumentationPage/DocumentationPage";

const DocumentationPageModal = ({ isMoldaOpen, setShowDocumentationModal }) => {
  const { createKey, getKeys } = useKeysContext();
  const { selectedProject } = useProjectContext();

  const [inputValue, setInputValue] = useState("");
  const ref = useRef();

  const { setLoading } = useOutletContext();

  const handleGetKeys = (projectId) => {
    getKeys(
      projectId,
      (response) => {
        setLoading(false);
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        setShowDocumentationModal &&
        ref.current &&
        !ref.current.contains(e.target)
      ) {
        setShowDocumentationModal(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setShowDocumentationModal]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShowDocumentationModal(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <FullscreenContainer>
      <Line>
        <HeadWrapper>
          <h1>Documentation</h1>
        </HeadWrapper>
        <CloneBtn
          onClick={() => setShowDocumentationModal(false)}
          style={{ marginLeft: "auto" }}
        >
          <CloseModalIcon />
        </CloneBtn>
      </Line>
      <DocumentationPage />
    </FullscreenContainer>
  );
};

export default DocumentationPageModal;

const FullscreenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 5;
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 30px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Distributes space between items */
  padding: 20px;
  width: 100%; /* Ensures it fills the entire container */
`;
