import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import SubscriptionPlanCard from "../../Components/SubscriptionsComponents/SubscriptionPlanCard";
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import IndividualSubscriptionTypeModal from "../../Components/SubscriptionsComponents/IndividualSubscriptionTypeModal";
import { useEffect } from "react";
import LoaderComponent from "../../Components/Generic/LoaderComponent";
import { showGenericError } from "../../helper/Notifications";
import { useLicensingContext } from "../../context/useLicensingContext";
import { useProjectContext } from "../../context/useProjectContext";
import SubscriptionCards from "./SubscriptionCards";
import SubscriptionDetails from "./SubscriptionDetails";

const SubscriptionsPage = () => {
  const [showIndividualOptions, setShowIndividualOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    subscriptionDetails,
    subscribeToOffer,
    cancelSubscription,
    dashboardURL,
    subscription,
    setSubscription,
  } = useLicensingContext();

  const [loaded, setLoaded] = useState(false);
  const { selectedProject } = useProjectContext();

  const contactSales = () => {
    const recipient = "support@emotii.ai"; // Change to the recipient's email address
    const subject = encodeURIComponent("Enterprise subscription plan"); // Pre-filled subject

    // Construct the mailto link
    const mailtoLink = `mailto:${recipient}?subject=${subject}`;

    // Open the mail client
    window.location.href = mailtoLink;
  };

  const purchaseSubscription = (type) => {
    if (type === "individual") {
      handlePurchaseIndividual();
    } else {
      purchaseSubscriptionWithName(type);
    }
  };

  const handlePurchaseIndividual = () => {
    setShowIndividualOptions(true);
  };

  const purchaseSubscriptionWithName = (name) => {
    if (!selectedProject) {
      return;
    }

    setLoading(true);
    subscribeToOffer(
      selectedProject.id,
      name,
      (response) => {
        setLoading(false);
        if (response.data.url) {
          const link = response.data.url;
          window.location = link;
        }
      },
      (error) => {
        setLoading(false);
        showGenericError();
        console.log(error);
      }
    );
  };

  const handleGetSubscriptionDetails = () => {
    if (!selectedProject) {
      return;
    }
    setLoading(true);
    subscriptionDetails(
      selectedProject.id,
      (response) => {
        setLoading(false);
        setSubscription(response.data.details);
        setLoaded(true);
      },
      (error) => {
        setLoading(false);
        showGenericError();
        console.log(error);
      }
    );
  };

  const handleCancelSubscription = () => {
    if (!selectedProject) {
      return;
    }
    setLoading(true);
    cancelSubscription(
      selectedProject.id,
      (response) => {
        setTimeout(() => {
          setLoading(false);
          handleGetSubscriptionDetails();
        }, 5000);
      },
      (error) => {
        setLoading(false);
        showGenericError();
        console.log(error);
      }
    );
  };

  const handleViewInvoices = () => {
    if (!selectedProject) {
      return;
    }
    setLoading(true);
    dashboardURL(
      selectedProject.id,
      (response) => {
        if (response.data.url) {
          const link = response.data.url;
          window.location = link;
        }
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        showGenericError();
        console.log(error);
      }
    );
  };

  useEffect(() => {
    handleGetSubscriptionDetails();
  }, [selectedProject]);

  return (
    <LoaderComponent loading={loading}>
      <Container>
        <AnimatePresence>
          {showIndividualOptions && (
            <IndividualSubscriptionTypeModal
              setIsModalOpen={setShowIndividualOptions}
              purchaseSubscription={(item) =>
                purchaseSubscriptionWithName(item)
              }
            />
          )}
        </AnimatePresence>
        <PageContent>
          <Header>
            <HeaderTitle>Subscription</HeaderTitle>
          </Header>
          <SubtitleContainer>
            <p>Plans</p>
          </SubtitleContainer>
          {loaded &&
            (subscription ? (
              <SubscriptionDetails
                subscription={subscription}
                cancelSubscription={handleCancelSubscription}
                viewInvoices={handleViewInvoices}
              />
            ) : (
              <SubscriptionCards
                purchaseSubscription={purchaseSubscription}
                contactSales={contactSales}
              />
            ))}
        </PageContent>
      </Container>
    </LoaderComponent>
  );
};

export default SubscriptionsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
  padding-bottom: 30px;
  padding: 40px 30px;
  padding-right: 0px;
  padding-bottom: 0px;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const HeaderTitle = styled.h1`
color:${lightThemeColors.darkColor};
font-size: 30px;
font-weight: 600;
line-height: 36px
text-align: left;
`;

const SubtitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start; /* Align the container's content to the left */

  color: ${lightThemeColors.darkColor};
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left; /* Align text to the left */
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  overflow: auto;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SubscriptionsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;
`;
