import { DELETE, GET, POST } from "../API";
let getProjectUsersSource;
export const getProjectsAPICall = (
    onSuccess,
    onError
) => {


    GET(
        process.env.REACT_APP_API_PATH + "/public_api/projects",
        onSuccess,
        onError
    );
};

export const createProjectAPICall = (
    name,
    members,
    onSuccess,
    onError
) => {
    const data = {
        name: name,
        members: members
    };

    POST(
        process.env.REACT_APP_API_PATH + "/public_api/projects/create",
        data,
        onSuccess,
        onError
    );
};

export const deleteProjectAPICall = (
    projectId,
    onSuccess,
    onError
) => {

    DELETE(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}`,
        onSuccess,
        onError
    );
};

export const getProjectUsageAPICall = (
    projectId,
    startDate,
    endDate,
    onSuccess,
    onError
) => {

    const data = {
        start_date: startDate,
        end_date: endDate
    }

    POST(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/licensing/usage`,
        data,
        onSuccess,
        onError
    );
};

export const getProjectUsersAPICall = (
    projectId,
    term,
    page,
    itemsPerPage,
    onSuccess,
    onError
) => {

    const data = {
        term: term,
        page: page,
        items_per_page: itemsPerPage
    }

    getProjectUsersSource = POST(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/licensing/project_users`,
        data,
        onSuccess,
        onError,
        true,
        getProjectUsersSource
    );
};

export const addProjectUsersAPICall = (
    projectId,
    emails,
    onSuccess,
    onError
) => {

    const data = {
        emails: emails
    }

    POST(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/licensing/add_project_users`,
        data,
        onSuccess,
        onError
    );
};

export const disableProjectUsersAPICall = (
    projectId,
    emails,
    onSuccess,
    onError
) => {

    const data = {
        emails: emails
    }

    POST(
        process.env.REACT_APP_API_PATH + `/public_api/projects/${projectId}/licensing/disable_project_users`,
        data,
        onSuccess,
        onError
    );
};