import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import PrimaryButton from "../../Components/Generic/PrimaryButton";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIcon.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copyIcon.svg";
import { ReactComponent as DotMenuIcon } from "../../assets/icons/dotMenuIcon.svg";
import SecondaryButton from "../../Components/Generic/SecondaryButton";
import { AnimatePresence } from "framer-motion";
import InviteMembersModal from "../../Components/MembersPage/InviteMembersModal";
import { useMembersContext } from "../../context/useMembersContext";
import { useProjectContext } from "../../context/useProjectContext";
import { useOutletContext, useSearchParams } from "react-router-dom";
import {
  showGenericError,
  showSuccessNotification,
} from "../../helper/Notifications";
import { useUserContext } from "../../context/useUserContext";
import DatePickerWithOptions from "../../Components/Generic/DatePickerWithOptions";
import moment from "moment";
import DeleteConfirmationChild from "../../Components/Generic/DeleteConfirmationWithChild";
import { DELETE_PROJECT } from "../../constants/OptionsConstants";
import { lastIndexOf } from "lodash";
import DocumentationPage from "../DocumentationPage/DocumentationPage";
import MembersPage from "../MembersPage/MembersPage";

const UsagePage = () => {
  const {
    selectedProject,
    getProjectUsage,
    deleteProject,
    getProjects,
    setSelectedProject,
  } = useProjectContext();
  const { user } = useUserContext();
  const { setLoading } = useOutletContext();

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);

  const today = new Date();
  const initialDateStart = moment(today)
    .subtract(29, "day")
    .startOf("day")
    .utc();
  const initialDateEnd = moment(today).endOf("day").utc();

  const [startDate, setStartDate] = useState(new Date(initialDateStart));
  const [endDate, setEndDate] = useState(new Date(initialDateEnd));

  const [usage, setUsage] = useState([]);

  const handleGetUsage = (projectId, startDate, endDate) => {
    let newStartDate = moment(startDate).startOf("day").toISOString();
    let newEndDate = moment(endDate).endOf("day").toISOString();
    setLoading(true);
    getProjectUsage(
      projectId,
      newStartDate,
      newEndDate,
      (response) => {
        setUsage(response.data.usage);
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const getNameFromItem = (item) => {
    console.log(item);

    if (item.api_key) {
      return "[API] " + item.api_key.name;
    }

    if (item.user) {
      return item.user.email;
    }

    if (item.anonymous_user) {
      return item.anonymous_user.email;
    }

    return "unknown";
  };

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    handleGetUsage(selectedProject.id, startDate, endDate);
  }, [startDate, endDate, selectedProject]);

  return (
    <Container>
      <PageContent>
        <Header>
          <HeaderTitle>Usage</HeaderTitle>
        </Header>
        <ContentWrapper>
          <Line>
            <Subtitle>Items</Subtitle>
            <div style={{ marginLeft: "auto" }}>
              <DatePickerWithOptions
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
            </div>
          </Line>
          <Line>
            <ListWrapper>
              <ListHeader>
                <HeaderColumn style={{ minWidth: "200px" }}>
                  <p>Identifier</p>
                </HeaderColumn>
                <HeaderColumn>
                  <p>Input tokens</p>
                </HeaderColumn>
                <HeaderColumn>
                  <p>Audio minutes</p>
                </HeaderColumn>
                <HeaderColumn>
                  <p>Video minutes</p>
                </HeaderColumn>
              </ListHeader>
              {usage?.map((item, index) => (
                <ListLine>
                  <ListColumn style={{ minWidth: "200px" }}>
                    <h2> {getNameFromItem(item)}</h2>
                  </ListColumn>
                  <ListColumn>
                    <h3> {item.input_tokens}</h3>
                  </ListColumn>
                  <ListColumn>
                    <h3> {item.audio?.toFixed(2)}</h3>
                  </ListColumn>
                  <ListColumn>
                    <h3> {item.video?.toFixed(2)}</h3>
                  </ListColumn>
                </ListLine>
              ))}
            </ListWrapper>
          </Line>
        </ContentWrapper>
      </PageContent>
    </Container>
  );
};

export default UsagePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
  padding-bottom: 30px;
  padding: 40px 30px;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderTitle = styled.h1`
color:${lightThemeColors.darkColor};
font-size: 30px;
font-weight: 600;
line-height: 36px
text-align: left;

`;
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ListHeader = styled(Line)`
  background: ${lightThemeColors.menuBackground};
  padding: 13px 20px;
  gap: 50px;
  width: 100%;
  min-width: 750px;
  min-height: 50px;
`;

const HeaderColumn = styled.div`
  display: flex;
  width: 100%;
  min-width: 125px;
  align-items: center;

  p {
    color: ${lightThemeColors.darkColor50};
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
  }
`;

const ListLine = styled(Line)`
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
  padding: 13px 20px;
  gap: 50px;
  width: 100%;
  min-width: 750px;
  min-height: 50px;
`;
const ListColumn = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 125px;
  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  overflow: auto;
`;
const Subtitle = styled.h2`
color:${lightThemeColors.darkColor};
font-size: 24px;
font-weight: 600;
line-height: 30px
text-align: left;
`;
