import { useEffect, useRef, useState } from "react";
import LoaderComponent from "./Components/Generic/LoaderComponent";
import { createSearchParams, Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import SideBarMenu from "./Components/SideNav/SideBarMenu";
import styled from "styled-components";
import { useProjectContext } from "./context/useProjectContext";
import { showGenericError } from "./helper/Notifications";
import { AnimatePresence } from "framer-motion";
import CreateNewProjectModal from "./Components/SideNav/CreateNewProjectModal";
import { lastIndexOf } from "lodash";
import { useKeysContext } from "./context/useKeysContext";
import { useMembersContext } from "./context/useMembersContext";
import { useLicensingContext } from "./context/useLicensingContext";

function App() {

  const { getProjects, selectedProject, setSelectedProject } = useProjectContext();
  const { subscriptionDetails, subscription, setSubscription } = useLicensingContext();

  const { getKeys } = useKeysContext();
  const { getMembers } = useMembersContext();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const [createNewProjectModalOpen, setCreateNewProjectModalOpen] =
    useState(false);
  const [createFirstProjectModalOpen, setCreateFirstProjectModalOpen] = useState(false);

  const firstLoginRef = useRef(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleGetProjects = () => {
    setLoading(true);

    getProjects(
      (response) => {
        handleProjectResponse(response.data.response);
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        showGenericError();
        console.log(error)
      },
    )
  }

  const handleProjectResponse = (projects) => {
    if (searchParams.get("project_id")) {
      let found = projects.find(
        (item) => item.id == searchParams.get("project_id")
      );
      if (found) {
        setSelectedProject(found);

      }
    } else {
      if (projects.length > 0) {
        firstLoginRef.current = true;
        const lastInstance = projects[lastIndexOf(projects) - 1];
        setSearchParams((params) => {
          params.set("project_id", lastInstance.id);
          return params;
        });
        setSelectedProject(lastInstance);
        setLoading(false);
      }
      else {
        setCreateFirstProjectModalOpen(true)
      }
    }
    handleLocation();
  };

  const handleGetKeys = (projectId) => {
    setLoading(true)
    getKeys(
      projectId,
      (response) => { setLoading(false) },
      (error) => {
        setLoading(false)
        showGenericError()
      }
    )
  }

  const handleGetMembers = (projectId) => {
    getMembers(
      projectId,
      (response) => { setLoading(false) },
      () => {
        showGenericError()
        setLoading(false)
      },
    )
  }

  const handleGetSubscriptionDetails = (projectId) => {
    setLoading(true);
    subscriptionDetails(
      projectId,
      (response) => {
        setLoading(false);
        setSubscription(response.data.details);
        if (!response.data.details) {
          navigate({
            pathname: "/subscriptions",
            search: createSearchParams(searchParams).toString(),
          });
        }

      },
      (error) => {
        setLoading(false);
        showGenericError();
        console.log(error);
      }
    );
  };


  const handleLocation = () => {
    if (location.pathname === "/" && subscription) {
      navigate({
        pathname: "/api-keys",
        search: createSearchParams(searchParams).toString()
      })
    } else {
      navigate({
        pathname: "/subscriptions",
        search: createSearchParams(searchParams).toString()
      })
    }
  }


  useEffect(() => {
    handleGetProjects()
  }, [])

  useEffect(() => {
    if (!selectedProject) {
      return
    }
    handleGetKeys(selectedProject.id);
    handleGetMembers(selectedProject.id);
    handleGetSubscriptionDetails(selectedProject.id);
  }, [selectedProject])



  return (
    <LoaderComponent loading={loading}>
      <Container>
        <SideBarMenu
          setCreateNewProjectModalOpen={setCreateNewProjectModalOpen}
          createNewProjectModalOpen={createNewProjectModalOpen}
        />
        <OutletWrapper>
          <Outlet
            context={{
              loading,
              setLoading,
              setCreateFirstProjectModalOpen
            }}
          />

        </OutletWrapper>

        <AnimatePresence>
          {createNewProjectModalOpen && (
            <CreateNewProjectModal
              setLoading={setLoading}
              isMoldaOpen={createNewProjectModalOpen}
              setIsModalOpen={setCreateNewProjectModalOpen}
            />
          )}

          {createFirstProjectModalOpen && (
            <CreateNewProjectModal
              setLoading={setLoading}
              isMoldaOpen={createFirstProjectModalOpen}
              setIsModalOpen={setCreateFirstProjectModalOpen}
              firstProject
            />
          )}
        </AnimatePresence>
      </Container>
    </LoaderComponent>


  );
}

export default App;

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;

const OutletWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;