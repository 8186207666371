import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import SecondaryButton from "../../Components/Generic/SecondaryButton";
import CustomInput from "../../Components/Generic/CustomInput";
import { useUserContext } from "../../context/useUserContext";
import { useNavigate, useOutletContext } from "react-router-dom";
import LocalStorage from "../../helper/LocalStorage";
import { showGenericError } from "../../helper/Notifications";
import { AnimatePresence } from "framer-motion";
import TwoFAModal from "./TwoFAModal";
import PrimaryButton from "../../Components/Generic/PrimaryButton";
import isEmail from "validator/lib/isEmail";
import ChangePasswordCard from "./ChangePasswordCard";
import DeleteConfirmationChild from "../../Components/Generic/DeleteConfirmationWithChild";
import { REMOVE_ACCOUNT } from "../../constants/OptionsConstants";

const ProfilePage = () => {
  const { updateUser, user, removeAccount, handleSetUser } = useUserContext();
  const [optRequired, setOtpRequired] = useState(false);
  const [show2FAModal, setShow2FAModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [infoChanges, setInfoChanges] = useState(true);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  const { setLoading } = useOutletContext();

  const navigate = useNavigate();

  const handleUpdateUser = () => {
    const data = {};
    if (name != user.name) {
      data.name = name;
    }
    if (name != user.email) {
      data.email = email;
    }
    updateUser(
      data,
      (response) => {},
      (error) => {
        showGenericError();
      }
    );
  };

  const handleRemoveAccount = () => {
    setLoading(true);
    removeAccount(
      (response) => {
        setLoading(false);
        navigate("/login");
      },
      (error) => {
        setLoading(false);
        showGenericError();
      }
    );
  };

  const handle2FABtn = () => {
    setShow2FAModal(true);
  };

  const checkInfoChanges = () => {
    if (!user) {
      return;
    }
    if (name != user.name || (email != user.email && isEmail(email))) {
      setInfoChanges(true);
    } else {
      setInfoChanges(false);
    }
  };

  const setInitialState = () => {
    setName(user.name);
    setEmail(user.email);
    setOtpRequired(user.otp_required_for_login);
  };

  useEffect(() => {
    setInitialState();
  }, [user]);

  useEffect(() => {
    checkInfoChanges();
  }, [name, email, user]);

  return (
    <Container>
      <AnimatePresence>
        {show2FAModal && <TwoFAModal setIsModalOpen={setShow2FAModal} />}
        {showChangePassword && (
          <ChangePasswordCard setIsModalOpen={setShowChangePassword} />
        )}
        {/* {showInfoModal &&
                    <RemoveAccountInfoModal
                        setIsModalOpen={setShowInfoModal}
                    />
                } */}
      </AnimatePresence>

      <PageContent>
        <Header>
          <HeaderTitle>Profile</HeaderTitle>
        </Header>
        <Content>
          <Line style={{ maxWidth: "800px" }}>
            <CustomInput
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              label={"Name"}
              placeholder={"Name"}
            />
            <CustomInput
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              label={"Email"}
              placeholder={"Email"}
            />
          </Line>
          <Line>
            <div
              style={{
                width: "100%",
                minHeight: "1px",
                background: lightThemeColors.sidebar.menuItemBorder,
              }}
            ></div>
          </Line>
          <Line style={{ gap: "20px" }}>
            <DeleteConfirmationChild
              open={deleteConfirmOpen}
              setOpen={setDeleteConfirmOpen}
              action={handleRemoveAccount}
              variant={REMOVE_ACCOUNT}
              children={
                <SecondaryButton
                  variant={lightThemeColors.authentication.inputErrorColor}
                  text={"Remove account"}
                  onClick={() => setDeleteConfirmOpen(true)}
                />
              }
            />

            <SecondaryButton
              text={user.otp_required_for_login ? "Disable 2FA" : "Enable 2FA"}
              onClick={() => handle2FABtn()}
            />
            <SecondaryButton
              text={"Change Password"}
              onClick={() => setShowChangePassword(true)}
            />
            {infoChanges && (
              <PrimaryButton
                text={"Save changes"}
                onClick={() => handleUpdateUser()}
              />
            )}
          </Line>
        </Content>
      </PageContent>
    </Container>
  );
};

export default ProfilePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
  padding-bottom: 30px;
  padding: 40px 30px;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderTitle = styled.h1`
color:${lightThemeColors.darkColor};
font-size: 30px;
font-weight: 600;
line-height: 36px
text-align: left;

`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
