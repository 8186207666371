import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { useUserContext } from "../../context/useUserContext";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { showErrorNotification } from "../../helper/Notifications";
import CustomInput from "../Generic/CustomInput";
import PrimaryButton from "../Generic/PrimaryButton";

const LoginCard = ({ setIsLoading }) => {
  const { userRef, loginUser, resetPassword, isOtpEnabled } = useUserContext();

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialValues = {
    email: "",
    password: "",
    otp: "",
  };

  const [inputValues, setInputvalues] = useState(initialValues);
  const [otpEnabled, setOtpEnabled] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleIsOtpEnabled = (email) => {
    isOtpEnabled(
      email,
      (response) => {
        const otpEnabled = response.data.otp_enabled;
        setOtpEnabled(otpEnabled);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleLogin = () => {
    setIsLoading(true);

    loginUser(
      inputValues.email,
      inputValues.password,
      inputValues.otp,
      function (response) {
        setIsLoading(false);

        if (location.state && location.state?.backTo != "/") {
          navigate({
            pathname: location.state?.backTo,
            search: createSearchParams(location.state?.search).toString(),
          });
        } else {
          navigate(`/api-keys`);
        }
      },
      function (error) {
        if (error.response.data.error === "invalid_grant") {
          showErrorNotification("Provided credentials are invalid");
        }
        if (error.response.data.error === "Invalid email or password") {
          showErrorNotification(
            "Your email, password or OTP code are incorrect!"
          );
        }

        if (error.response.data.error === "User is not confirmed") {
          showErrorNotification(
            "Before loging in, please confirm your email account, follow the instructions in the confirmation email. If you can't find the email, check your spam folder."
          );
        }

        setIsLoading(false);
        console.log("error");
      }
    );
  };

  const checkSignInDisabled = () => {
    if (otpEnabled) {
      return (
        inputValues.email.length < 4 ||
        inputValues.password.length < 3 ||
        inputValues.otp.length < 6
      );
    } else {
      return inputValues.email.length < 4 || inputValues.password.length < 3;
    }
  };

  const handleSignInBtn = () => {
    handleLogin();
  };

  const handleCreateAccountBtn = () => {
    navigate(`/register`);
  };

  const handleForgotPasswordBtn = () => {
    navigate(`/reset_password`);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleIsOtpEnabled(inputValues.email);
    }, 250);
    return () => {
      clearTimeout(timeout);
    };
  }, [inputValues.email]);

  return (
    <Card>
      <TitleContainer>
        <h2>Authenticate</h2>
      </TitleContainer>
      <InputsWrapper>
        <CustomInput
          label={"Email"}
          placeholder="Enter your e-mail adress"
          name={"email"}
          value={inputValues.email}
          onChange={handleInputChange}
        />
        <Line>
          <CustomInput
            type={"password"}
            label={"Password"}
            placeholder="Enter your password"
            name={"password"}
            value={inputValues.password}
            onChange={handleInputChange}
            bottomActionText={"Forgot password?"}
            bottomActionClick={() => handleForgotPasswordBtn()}
          />
        </Line>
        {otpEnabled && (
          <Line>
            <CustomInput
              type={"text"}
              label={"OTP Code"}
              placeholder="Enter your OTP code"
              name={"otp"}
              value={inputValues.otp}
              onChange={handleInputChange}
            />
          </Line>
        )}
      </InputsWrapper>
      <SbmitWrapper>
        <PrimaryButton
          styled={{ width: "100%" }}
          disabled={checkSignInDisabled()}
          text={"Sign in"}
          onClick={handleSignInBtn}
        />
        <p>
          Not a member?{" "}
          <span onClick={() => handleCreateAccountBtn()}>
            Create an account
          </span>
        </p>
      </SbmitWrapper>
    </Card>
  );
};

export default LoginCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  min-width: 530px;
  background: ${lightThemeColors.background};
  min-height: 550px;
  gap: 30px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  box-shadow: 0px 0px 40px 0px rgba(151, 168, 190, 0.2);
  z-index: 1;
  @media (max-width: 650px) {
    width: 100%;
    min-width: unset;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 900;
    font-size: 25px;
  }
  h2 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
  }
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const ForgotPwd = styled.div`
  width: fit-content;
  p {
    width: fit-content;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;
const SbmitWrapper = styled.div`
  margin-top: auto;

  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  span {
    margin-left: 2px;
    font-weight: 700;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;
const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;
