import React, { useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/CloseModalIcon.svg";
import Select from "react-select";
import { motion } from "framer-motion";
import { useOutletContext } from "react-router-dom";
import cloneDeep from "clone-deep";

import PrimaryButton from "../Generic/PrimaryButton";
import { useProjectContext } from "../../context/useProjectContext";
import { showErrorNotification, showGenericError, showSuccessNotification } from "../../helper/Notifications";

const InviteMultipleUsersModal = ({ isMoldaOpen, setIsModalOpen, handleGetProjectUsers }) => {

  const [inputValue, setInputValue] = useState("");
  const { selectedProject, addProjectUsers } = useProjectContext();

  const ref = useRef();

  const { setLoading } = useOutletContext();

  const handleAddUsers = () => {
    let newArray = inputValue.replace(/\n/g, ',').replace(/\s+/g, '').split(',').filter((item) => item !== "");

    setLoading(true)
    addProjectUsers(
      selectedProject.id,
      newArray,
      (response) => {
        handleGetProjectUsers();
        setLoading(false);
        setIsModalOpen(false);
      },
      (error) => {
        if (error?.response?.data?.message === "Can not add users, they are already enabled in this or other projects") {
          setLoading(false)
          setIsModalOpen(false);
          showErrorNotification(
            `Can not add users, they are already enabled in this or other projects. ${error.response.data.users}`
          )
        } else {
          showGenericError()
          setLoading(false)
        }

      },
    )
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsModalOpen]);


  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);


  return (
    <FullscreenContainer>
      <InviteModal
        ref={ref}
        as={motion.div}
        initial={{ translateX: "65vw" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "65vw" }}
        transition={{ duration: 0.4 }}
      >

        <Line>
          <HeadWrapper>
            <h1>Add users</h1>
          </HeadWrapper>
          <CloneBtn
            onClick={() => setIsModalOpen(false)}
            style={{ marginLeft: "auto" }}
          >
            <CloseModalIcon />
          </CloneBtn>
        </Line>

        <Line>
          <CustomTextArea
            placeholder={"Add one or more email addresses from the users that will access the app on you behalf. If adding more addresses, separate the by ‘,’ "}
            value={inputValue}
            onChange={(e) => setInputValue(e.currentTarget.value)}
          />


        </Line>


        <Line>
          <PrimaryButton
            disabled={!inputValue}
            styled={{ padding: "12px", width: "100%" }}
            text={"Add"}
            onClick={() => handleAddUsers()}
          />
        </Line>
      </InviteModal>
    </FullscreenContainer>
  );
};

export default InviteMultipleUsersModal;

const FullscreenContainer = styled.div`
  display: flex;
  align-items:center;
  justify-content:center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const InviteModal = styled.div`

  position: fixed;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 400px;
  max-height:600px;
margin-left:auto;
margin-right:auto;
width:100%;
  padding: 30px;
  border-radius:10px;
  gap: 20px;
  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
 display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
    svg{
  width:20px;
  height:20px;
  }
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction:column;
  overflow-x:hidden;
  overflow-y:auto;
height:100%;
`
const ListTitle = styled.p`
font-size:14px;
font-weight:500;
color:${lightThemeColors.darkColor};
line-height:20px;
`
const CustomTextArea = styled.textarea`
background:none;
min-height:200px;

 padding: 10px 15px ;
  outline: none;
  width: 100%;
  color: ${lightThemeColors.darkColor};
  border-radius: 5px;
  border-color:${lightThemeColors.sidebar.requiredBg};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  &::placeholder{
  color:rgba(214, 216, 219, 1);
  }


resize:none;
`