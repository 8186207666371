import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";

import {
  addProjectUsersAPICall,
  createProjectAPICall,
  deleteProjectAPICall,
  disableProjectUsersAPICall,
  getProjectsAPICall,
  getProjectUsageAPICall,
  getProjectUsersAPICall,
} from "../api/projects/projectService";

export const ProjectContext = createContext({
  projects: [],
  selectedProject: [],
  selectedProjectRef: [],
  projectUsers: [],
  setProjects: () => {},
  setSelectedProject: () => {},
  getProjects: () => {},
  createProject: () => {},
  getProjectUsage: () => {},
  deleteProject: () => {},
  setProjectUsers: () => {},
  getProjectUsers: () => {},
  addProjectUsers: () => {},
  disableProjectUsers: () => {},
});

const ProjectContextProvider = ({ children, setLoading }) => {
  const [projects, setProjects] = useState(undefined);
  const [selectedProject, setSelectedProject] = useState(undefined);
  const [projectUsers, setProjectUsers] = useState([]);

  const selectedProjectRef = useRef(null);

  const getProjects = useCallback(async (onSuccess, onError) => {
    getProjectsAPICall(function (response) {
      setProjects(response.data.response);
      onSuccess(response);
    }, onError);
  });

  const createProject = useCallback(
    async (name, members, onSuccess, onError) => {
      createProjectAPICall(
        name,
        members,
        function (response) {
          setProjects(response.data.response);
          onSuccess(response);
        },
        onError
      );
    }
  );
  const deleteProject = useCallback(async (projectId, onSuccess, onError) => {
    deleteProjectAPICall(
      projectId,
      function (response) {
        onSuccess(response);
      },
      onError
    );
  });

  const getProjectUsage = useCallback(
    async (projectId, startDate, endDate, onSuccess, onError) => {
      getProjectUsageAPICall(
        projectId,
        startDate,
        endDate,
        function (response) {
          onSuccess(response);
        },
        onError
      );
    }
  );

  const handleProjectresponse = (response) => {
    setProjects(response.data.project);
  };

  const getProjectUsers = useCallback(
    async (projectId, term, page, itemPerPage, onSuccess, onError) => {
      getProjectUsersAPICall(
        projectId,
        term,
        page,
        itemPerPage,
        function (response) {
          setProjectUsers(response.data.response);
          onSuccess(response);
        },
        onError
      );
    }
  );

  const addProjectUsers = useCallback(
    async (projectId, users, onSuccess, onError) => {
      addProjectUsersAPICall(
        projectId,
        users,
        function (response) {
          // setProjectUsers(response)
          onSuccess(response);
        },
        onError
      );
    }
  );

  const disableProjectUsers = useCallback(
    async (projectId, users, onSuccess, onError) => {
      disableProjectUsersAPICall(
        projectId,
        users,
        function (response) {
          // setProjectUsers(response)
          onSuccess(response);
        },
        onError
      );
    }
  );

  return (
    <ProjectContext.Provider
      value={{
        projects,
        setProjects,
        selectedProject,
        setSelectedProject,
        selectedProjectRef,
        getProjects,
        createProject,
        deleteProject,
        getProjectUsage,
        projectUsers,
        setProjectUsers,
        getProjectUsers,
        addProjectUsers,
        disableProjectUsers,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjectContext = () => useContext(ProjectContext);

export default ProjectContextProvider;
