import React, { useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { useUserContext } from "../../context/useUserContext";
import { isPasswordValid, passwordsMatch } from "../../helper/ValidatorsHelper";
import { showGenericError } from "../../helper/Notifications";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomInput from "../Generic/CustomInput";
import PrimaryButton from "../Generic/PrimaryButton";
import { ReactComponent as ShowIcon } from "../../assets/icons/ShowIcon.svg"
import ReactPasswordChecklist from "react-password-checklist";


const NewPasswordCard = ({ setIsLoading }) => {
  const { confirmForgotPassword } = useUserContext();
  const navigate = useNavigate();
  const initialValues = {
    password: "",
    password_confirm: "",
  };

  const [inputValues, setInputvalues] = useState(initialValues);

  const [searchParams] = useSearchParams();
  const resetTokenRef = useRef();
  const passwordRef = useRef(null);
  const passwordConfRef = useRef(null);
  const [showConditions, setShowConditions] = useState(false);
  const [passwordRulesValid, setPasswordRulesValid] = useState(false);


  resetTokenRef.current = searchParams.get("token");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleShowPassword = (ref) => {
    let element = ref.current


    if (element.type === "password") {
      element.type = "text"
    } else {
      element.type = "password"
    }

  }

  const handleResetBtn = () => {
    if (!passwordRulesValid) {
      setShowConditions(true);
      return
    }


    if (
      isPasswordValid(inputValues.password) &&
      passwordsMatch(inputValues.password, inputValues.password_confirm) &&
      passwordRulesValid &&
      resetTokenRef.current
    ) {
      setIsLoading(true);
      confirmForgotPassword(
        resetTokenRef.current,
        inputValues.password,
        () => {
          setIsLoading(false);
          navigate("/login", { replace: true });
        },
        (error) => {
          showGenericError();
          setIsLoading(false);
        }
      );
    }
  };

  const handleCreateAccountBtn = () => {
    navigate("/register");
  };


  return (
    <Card>
      <TitleContainer>
        <h2>New Password</h2>
      </TitleContainer>
      <Subtitle>
        <p>Pick a new password to use for your login credentials.</p>
      </Subtitle>
      <InputsWrapper>
        <CustomInput
          type={"password"}
          label={"Confirm Password"}
          placeholder="New password"
          name={"password"}
          value={inputValues.password}
          onChange={handleInputChange}
          inputRef={passwordRef}
          customButtonStyle={{ marginRight: "10px" }}
          customButton={
            <ShowPasswordBtn onClick={() => handleShowPassword(passwordRef)}>
              <ShowIcon />
            </ShowPasswordBtn>

          }
        />

        <CustomInput
          type={"password"}
          label={"Confirm Password"}
          placeholder="New password confirm"
          name={"password_confirm"}
          value={inputValues.password_confirm}
          onChange={handleInputChange}
          inputRef={passwordConfRef}
          customButtonStyle={{ marginRight: "10px" }}
          customButton={
            <ShowPasswordBtn onClick={() => handleShowPassword(passwordConfRef)}>
              <ShowIcon />
            </ShowPasswordBtn>

          }
        />

        <div style={showConditions ? { display: "flex" } : { display: "none" }}>
          <ReactPasswordChecklist
            iconSize={14}
            minLength={6}
            value={inputValues.password}
            valueAgain={inputValues.password_confirm}
            rules={["minLength", "specialChar", "capital", "lowercase", "number", "match"]}
            onChange={(isValid) => { setPasswordRulesValid(isValid) }}
            className="password-rules"
          />

        </div>
      </InputsWrapper>

      <SbmitWrapper>
        <PrimaryButton
          styled={{ width: "100%" }}
          text={"Change password"}
          onClick={handleResetBtn}
          disabled={!(isPasswordValid(inputValues.password) && isPasswordValid(inputValues.password_confirm))}
        />

      </SbmitWrapper>
    </Card>
  );
};

export default NewPasswordCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  min-width: 530px;
  background: ${lightThemeColors.background};
  min-height: 550px;
  gap: 30px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  z-index:1;
      @media(max-width:650px){
  width:100%;
  min-width: unset;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 900;
    font-size: 25px;
  }
  h2 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
  }
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const ForgotPwd = styled.div`
  width: 100%;
  margin-top: -25px;
  p {
    width: fit-content;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;
const SbmitWrapper = styled.div`
  margin-top: auto;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  p {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  span {
    margin-left: 4px;
    font-weight: 700;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;

const Subtitle = styled.div`
  width: 100%;
  display: flex;

  p {
    color: ${lightThemeColors.darkColorFaded};
    opacity: 0.3;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
`;
const ShowPasswordBtn = styled.button`
border:none;
background:none;
cursor:pointer;
display:flex;
align-items:center;
jutify-content:center;
margin-right:10px;
svg{
color:${lightThemeColors.darkColor50};
}
`