import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import PrimaryButton from "../../Components/Generic/PrimaryButton";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIcon.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copyIcon.svg";
import { ReactComponent as DotMenuIcon } from "../../assets/icons/dotMenuIcon.svg";
import SecondaryButton from "../../Components/Generic/SecondaryButton";
import { AnimatePresence } from "framer-motion";
import InviteMembersModal from "../../Components/MembersPage/InviteMembersModal";
import { useMembersContext } from "../../context/useMembersContext";
import { useProjectContext } from "../../context/useProjectContext";
import { useOutletContext, useSearchParams } from "react-router-dom";
import {
  showGenericError,
  showSuccessNotification,
} from "../../helper/Notifications";
import { useUserContext } from "../../context/useUserContext";
import DatePickerWithOptions from "../../Components/Generic/DatePickerWithOptions";
import moment from "moment";
import DeleteConfirmationChild from "../../Components/Generic/DeleteConfirmationWithChild";
import { DELETE_PROJECT } from "../../constants/OptionsConstants";
import { lastIndexOf } from "lodash";
import DocumentationPage from "../DocumentationPage/DocumentationPage";
import MembersPage from "../MembersPage/MembersPage";

const ProjectPage = () => {
  const {
    selectedProject,
    getProjectUsage,
    deleteProject,
    getProjects,
    setSelectedProject,
  } = useProjectContext();
  const { setLoading } = useOutletContext();

  const [deleteConfirmationModalOpen, setDeleteConfirmationModalOpen] =
    useState(false);

  const { setCreateFirstProjectModalOpen } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleGetProjects = () => {
    setLoading(true);
    getProjects(
      (response) => {
        handleProjectResponse(response.data.response);
        setLoading(false);
      },
      (error) => {
        setLoading(false);
        showGenericError();
        console.log(error);
      }
    );
  };

  const handleProjectResponse = (projects) => {
    if (projects.length > 0) {
      const lastInstance = projects[lastIndexOf(projects) - 1];
      setSearchParams((params) => {
        params.set("project_id", lastInstance.id);
        return params;
      });
      setSelectedProject(lastInstance);
      setLoading(false);
    } else {
      setCreateFirstProjectModalOpen(true);
    }
  };

  const handleDeleteProject = () => {
    deleteProject(
      selectedProject.id,
      (response) => {
        setSearchParams((prev) => {
          prev.delete("project_id");
        });
        handleGetProjects();
      },
      (error) => {
        console.log(error);
        showGenericError();
      }
    );
  };

  return (
    <Container>
      <PageContent>
        <Header>
          <HeaderTitle>Settings</HeaderTitle>
        </Header>
        <ContentWrapper>
          <Line>
            <MembersPage />
          </Line>
          <Line>
            <DeleteConfirmationChild
              open={deleteConfirmationModalOpen}
              setOpen={setDeleteConfirmationModalOpen}
              item={selectedProject}
              action={handleDeleteProject}
              variant={DELETE_PROJECT}
            >
              <SecondaryButton
                onClick={() => setDeleteConfirmationModalOpen(true)}
                variant={lightThemeColors.authentication.inputErrorColor}
                text={"Delete project"}
              />
            </DeleteConfirmationChild>
          </Line>
        </ContentWrapper>
      </PageContent>
    </Container>
  );
};

export default ProjectPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
  padding-bottom: 30px;
  padding: 40px 30px;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderTitle = styled.h1`
color:${lightThemeColors.darkColor};
font-size: 30px;
font-weight: 600;
line-height: 36px
text-align: left;

`;
const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const ListHeader = styled(Line)`
  background: ${lightThemeColors.menuBackground};
  padding: 13px 20px;
  gap: 50px;
  width: 100%;
  min-width: 750px;
  min-height: 50px;
`;

const HeaderColumn = styled.div`
  display: flex;
  width: 100%;
  min-width: 125px;
  align-items: center;

  p {
    color: ${lightThemeColors.darkColor50};
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    text-align: left;
  }
`;

const ListLine = styled(Line)`
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
  padding: 13px 20px;
  gap: 50px;
  width: 100%;
  min-width: 750px;
  min-height: 50px;
`;
const ListColumn = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 125px;
  h3 {
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
  }

  h2 {
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${lightThemeColors.darkColor};
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  height: 100%;
  overflow: auto;
`;
const Subtitle = styled.h2`
color:${lightThemeColors.darkColor};
font-size: 24px;
font-weight: 600;
line-height: 30px
text-align: left;
`;
